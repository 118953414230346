import React from 'react';
import { Mail, Lock, User } from 'lucide-react';

const InputField = ({ icon: Icon, ...props }) => (
  <div className="relative mb-4">
    <input
      {...props}
      className="w-full py-2 pl-10 pr-3 border-b border-gray-300 focus:border-black outline-none transition-colors"
    />
    <Icon className="absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
  </div>
);

const Button = ({ children, ...props }) => (
  <button
    {...props}
    className="w-full bg-black text-white py-2 rounded hover:bg-gray-800 transition-colors"
  >
    {children}
  </button>
);

const LoginSignupPage = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <main className="flex-grow flex flex-col md:flex-row justify-center items-center px-4 py-12">
        <div className="w-full max-w-md md:w-1/2 mb-8 md:mb-0 md:pr-8">
          <h2 className="text-3xl font-bold mb-6">Login</h2>
          <form>
            <InputField icon={Mail} type="email" placeholder="Your email" />
            <InputField icon={Lock} type="password" placeholder="Your password" />
            <div className="flex items-center justify-between mb-4">
              <label className="flex items-center">
                <input type="checkbox" className="mr-2" />
                <span className="text-sm">Remember me</span>
              </label>
              <a href="/login" className="text-sm text-blue-600 hover:underline">Forgot password?</a>
            </div>
            <Button>Login</Button>
          </form>
        </div>

        <div className="hidden md:block w-px bg-gray-300 h-64 mx-8"></div>

        <div className="w-full max-w-md md:w-1/2 md:pl-8">
          <h2 className="text-3xl font-bold mb-6">Sign Up</h2>
          <form>
            <InputField icon={User} type="text" placeholder="Your full name" />
            <InputField icon={Mail} type="email" placeholder="Your email" />
            <InputField icon={Lock} type="password" placeholder="Create a password" />
            <div className="mb-4">
              <label className="flex items-center">
                <input type="checkbox" className="mr-2" />
                <span className="text-sm">I agree to the <a href="/google.es" className="text-blue-600 hover:underline">Terms and Conditions</a></span>
              </label>
            </div>
            <Button>Sign Up</Button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default LoginSignupPage;