import React, { useState } from 'react';
import Modal from '../Modal';

const AddPropertyModal = ({ isOpen, onClose }) => {
  const [propertyData, setPropertyData] = useState({
    title: '',
    type: '',
    status: 'active',
    price: '',
    currency: 'MAD',
    bedrooms: '',
    bathrooms: '',
    area: '',
    areaUnit: 'sqm',
    address: '',
    city: '',
    neighborhood: '',
    description: '',
    features: [],
    propertyTax: '',
    yearBuilt: '',
    parkingSpaces: '',
    view: '',
    energyRating: '',
    furnished: false,
    petFriendly: false,
    garden: false,
    pool: false,
    securitySystem: false,
    nearbyAmenities: '',
    virtualTourLink: '',
    contactPerson: '',
    contactPhone: '',
    contactEmail: '',
    commissionRate: '',
    exclusivity: false,
    marketingDescription: '',
    keywords: '',
    language: 'fr'
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPropertyData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFeatureChange = (e) => {
    const { value } = e.target;
    setPropertyData(prevData => ({
      ...prevData,
      features: prevData.features.includes(value)
        ? prevData.features.filter(f => f !== value)
        : [...prevData.features, value]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting property data:', propertyData);
    // Here you would typically send the data to your backend
    onClose();
  };

  const features = [
    'Furnished', 'Pet Friendly', 'Garden', 'Pool', 'Security System',
    'Parking', 'Elevator', 'Air Conditioning', 'Heating', 'Balcony',
    'Terrace', 'Sea View', 'Mountain View', 'City View'
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Add New Property">
      <form onSubmit={handleSubmit} className="space-y-6 max-h-[70vh] overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={propertyData.title}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
            <select
              id="type"
              name="type"
              value={propertyData.type}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            >
              <option value="">Select type</option>
              <option value="apartment">Apartment</option>
              <option value="house">House</option>
              <option value="villa">Villa</option>
              <option value="riad">Riad</option>
              <option value="commercial">Commercial</option>
              <option value="land">Land</option>
            </select>
          </div>
          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
            <select
              id="status"
              name="status"
              value={propertyData.status}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="active">Active</option>
              <option value="pending">Pending</option>
              <option value="sold">Sold</option>
              <option value="rented">Rented</option>
            </select>
          </div>
          <div>
            <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="number"
                id="price"
                name="price"
                value={propertyData.price}
                onChange={handleChange}
                className="flex-1 rounded-none rounded-l-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                required
              />
              <select
                name="currency"
                value={propertyData.currency}
                onChange={handleChange}
                className="rounded-none rounded-r-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="MAD">MAD</option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="bedrooms" className="block text-sm font-medium text-gray-700">Bedrooms</label>
            <input
              type="number"
              id="bedrooms"
              name="bedrooms"
              value={propertyData.bedrooms}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="bathrooms" className="block text-sm font-medium text-gray-700">Bathrooms</label>
            <input
              type="number"
              id="bathrooms"
              name="bathrooms"
              value={propertyData.bathrooms}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="area" className="block text-sm font-medium text-gray-700">Area</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="number"
                id="area"
                name="area"
                value={propertyData.area}
                onChange={handleChange}
                className="flex-1 rounded-none rounded-l-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <select
                name="areaUnit"
                value={propertyData.areaUnit}
                onChange={handleChange}
                className="rounded-none rounded-r-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="sqm">m²</option>
                <option value="sqft">ft²</option>
                <option value="hectare">hectare</option>
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={propertyData.city}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="neighborhood" className="block text-sm font-medium text-gray-700">Neighborhood</label>
            <input
              type="text"
              id="neighborhood"
              name="neighborhood"
              value={propertyData.neighborhood}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
        
        <div>
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={propertyData.address}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            id="description"
            name="description"
            value={propertyData.description}
            onChange={handleChange}
            rows="3"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          ></textarea>
        </div>
        
        <div>
          <label htmlFor="features" className="block text-sm font-medium text-gray-700 mb-2">Features</label>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {features.map((feature) => (
              <label key={feature} className="inline-flex items-center">
                <input
                  type="checkbox"
                  value={feature}
                  checked={propertyData.features.includes(feature)}
                  onChange={handleFeatureChange}
                  className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <span className="ml-2 text-sm text-gray-700">{feature}</span>
              </label>
            ))}
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="yearBuilt" className="block text-sm font-medium text-gray-700">Year Built</label>
            <input
              type="number"
              id="yearBuilt"
              name="yearBuilt"
              value={propertyData.yearBuilt}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="energyRating" className="block text-sm font-medium text-gray-700">Energy Rating</label>
            <input
              type="text"
              id="energyRating"
              name="energyRating"
              value={propertyData.energyRating}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
        
        <div>
          <label htmlFor="nearbyAmenities" className="block text-sm font-medium text-gray-700">Nearby Amenities</label>
          <input
            type="text"
            id="nearbyAmenities"
            name="nearbyAmenities"
            value={propertyData.nearbyAmenities}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="contactPerson" className="block text-sm font-medium text-gray-700">Contact Person</label>
            <input
              type="text"
              id="contactPerson"
              name="contactPerson"
              value={propertyData.contactPerson}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="contactPhone" className="block text-sm font-medium text-gray-700">Contact Phone</label>
            <input
              type="tel"
              id="contactPhone"
              name="contactPhone"
              value={propertyData.contactPhone}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">Contact Email</label>
            <input
              type="email"
              id="contactEmail"
              name="contactEmail"
              value={propertyData.contactEmail}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="commissionRate" className="block text-sm font-medium text-gray-700">Commission Rate (%)</label>
            <input
              type="number"
              id="commissionRate"
              name="commissionRate"
              value={propertyData.commissionRate}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Exclusivity</label>
            <label className="inline-flex items-center mt-1">
              <input
                type="checkbox"
                name="exclusivity"
                checked={propertyData.exclusivity}
                onChange={handleChange}
                className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <span className="ml-2">Exclusive listing</span>
            </label>
          </div>
        </div>
        
        <div>
          <label htmlFor="marketingDescription" className="block text-sm font-medium text-gray-700">Marketing Description</label>
          <textarea
            id="marketingDescription"
            name="marketingDescription"
            value={propertyData.marketingDescription}
            onChange={handleChange}
            rows="3"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          ></textarea>
        </div>
        
        <div>
          <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">Keywords (comma-separated)</label>
          <input
            type="text"
            id="keywords"
            name="keywords"
            value={propertyData.keywords}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        
        <div>
          <label htmlFor="language" className="block text-sm font-medium text-gray-700">Listing Language</label>
          <select
            id="language"
            name="language"
            value={propertyData.language}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="fr">French</option>
            <option value="ar">Arabic</option>
            <option value="en">English</option>
          </select>
        </div>
        
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800"
          >
            Add Property
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddPropertyModal;