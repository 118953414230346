// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

const Navbar = () => {
  return (
    <nav className="border-b border-gray-200 py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="font-bold text-xl text-black font-playfair">Kasaba</Link>
        <div className="space-x-6 flex items-center">
          <Link to="/dashboard" className="text-black hover:text-gray-600 transition duration-300 font-lato">
            Dashboard
          </Link>
          <Link to="/clients" className="text-black hover:text-gray-600 transition duration-300 font-lato">
            Clients
          </Link>
          <Link to="/properties" className="text-black hover:text-gray-600 transition duration-300 font-lato">
            Properties
          </Link>
          <Link to="/pricing" className="text-black hover:text-gray-600 transition duration-300 font-lato">
            Pricing
          </Link>
          <Link to="/contact" className="text-black hover:text-gray-600 transition duration-300 font-lato">
            Contact
          </Link>
          <Link to="/login">
            <Button className="ml-4 py-1 px-4">Login</Button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;