// src/pages/PricingPage.js
import React from 'react';
import { CheckCircle } from 'lucide-react';
import Button from '../components/Button';

const PricingPage = () => {
  const pricingTiers = [
    {
      name: "Standard",
      price: "750 MAD",
      features: [
        "Property Management",
        "Client Database",
        "Basic Reporting",
        "Transaction Management",
        "Email Support"
      ]
    },
    {
      name: "Premium",
      price: "1500 MAD",
      features: [
        "All Standard Features",
        "Team Collaboration",
        "Advanced Analytics",
        "Marketing Tools",
        "Priority Support",
        "Custom Integrations"
      ]
    }
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl md:text-4xl font-semibold mb-16 text-center font-playfair">Simple, Transparent Pricing</h1>
      <div className="flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-5xl mx-auto">
        {pricingTiers.map((tier, index) => (
          <div key={index} className="flex-1 border border-gray-200 rounded-lg p-8 text-center flex flex-col justify-between transition-all duration-300 hover:shadow-xl">
            <div>
              <h3 className="text-2xl font-semibold mb-4 font-playfair">{tier.name}</h3>
              <p className="text-4xl font-bold mb-6 font-playfair">{tier.price}<span className="text-sm font-normal">/month</span></p>
              <ul className="mb-8 text-left">
                {tier.features.map((feature, i) => (
                  <li key={i} className="mb-3 flex items-center font-lato">
                    <CheckCircle className="text-black mr-2 flex-shrink-0" size={20} />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <Button>
              Choose {tier.name}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPage;