// src/pages/HomePage.js
import React from 'react';
import { ArrowRight, CheckCircle } from 'lucide-react';
import Button from '../components/Button';

const FeatureSection = ({ features }) => (
  <section className="mb-32">
    <h2 className="text-3xl md:text-4xl font-semibold mb-16 text-center font-playfair">Powerful Tools for Modern Real Estate Professionals</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-4xl mx-auto">
      {features.map((feature, index) => (
        <div key={index} className="flex items-center">
          <CheckCircle className="text-black mr-3 flex-shrink-0" />
          <span className="text-lg font-lato">{feature}</span>
        </div>
      ))}
    </div>
  </section>
);

const HowItWorksSection = ({ steps }) => (
  <section className="mb-32">
    <h2 className="text-3xl md:text-4xl font-semibold mb-16 text-center font-playfair">How It Works</h2>
    <div className="flex flex-col md:flex-row justify-between items-center max-w-6xl mx-auto">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-col items-center mb-8 md:mb-0 w-full md:w-1/4">
            <div className="bg-black text-white rounded-full w-12 h-12 flex items-center justify-center mb-4 font-playfair">
              {index + 1}
            </div>
            <h3 className="font-semibold mb-2 text-center font-playfair">{step.title}</h3>
            <p className="text-gray-600 text-center font-lato">{step.description}</p>
          </div>
          {index < steps.length - 1 && (
            <ArrowRight className="text-black hidden md:block flex-shrink-0 mx-2" size={24} />
          )}
        </React.Fragment>
      ))}
    </div>
  </section>
);

const HomePage = () => {
  const features = [
    "Property Management",
    "Client Management",
    "Transaction Management",
    "Financial Tools",
    "Team Collaboration",
    "Marketing Tools",
    "Reporting and Analytics"
  ];

  const reasons = [
    { title: "Tailored for Morocco", description: "Designed specifically for Moroccan real estate practices and market needs." },
    { title: "All-in-One Solution", description: "Comprehensive tool covering all aspects of real estate management." },
    { title: "Scalable", description: "Suitable for both independent agents and large agencies." },
    { title: "Local Language Support", description: "Interface available in Arabic, French, and potentially Berber." }
  ];

  const howItWorks = [
    { title: "Sign Up", description: "Create your account and choose a plan" },
    { title: "Import Data", description: "Easily import your existing property and client data" },
    { title: "Customize", description: "Set up your preferences and team members" },
    { title: "Start Managing", description: "Begin using the tools to streamline your operations" }
  ];

  return (
    <>
      <div className="bg-white-100 py-8 px-4 sm:px-6 lg:px-8"> {/* Added padding and background color */}
        <header className="relative w-full rounded-3xl overflow-hidden mx-auto" style={{ paddingTop: '56.25%', maxWidth: '1800px' }}> {/* Maintained 16:9 aspect ratio, added rounding and max-width */}
          <div className="absolute inset-0">
            <div 
              className="w-full h-full bg-cover bg-center bg-no-repeat rounded-3xl"
              style={{
                backgroundImage: "url('/images/real-estate-background.png')",
              }}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="bg-white bg-opacity-90 p-8 md:p-12 rounded-xl max-w-4xl mx-4 text-center">
                  <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-tight font-playfair">Revolutionize Your Real Estate Business in Morocco</h1>
                  <p className="text-lg md:text-xl lg:text-2xl mb-8 max-w-3xl mx-auto font-lato">Empower your agency with our all-in-one management solution</p>
                  <Button className="text-lg py-3 px-8">Start Free Trial</Button>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      <main className="container mx-auto px-4 py-16">
        <FeatureSection features={features} />
        <HowItWorksSection steps={howItWorks} />

        <section className="mb-32">
          <h2 className="text-3xl md:text-4xl font-semibold mb-16 text-center font-playfair">Why Choose Us?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-10 max-w-4xl mx-auto">
            {reasons.map((item, index) => (
              <div key={index} className="border-l-4 border-black pl-4">
                <h3 className="text-xl font-semibold mb-3 font-playfair">{item.title}</h3>
                <p className="text-gray-600 font-lato">{item.description}</p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </>
  );
};

export default HomePage;