import React from 'react';
import Modal from './Modal';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Setup the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

const AppointmentCalendarModal = ({ isOpen, onClose, appointments }) => {
  // Convert appointments to the format expected by react-big-calendar
  const events = appointments.map(apt => ({
    title: apt.title,
    start: new Date(apt.start),
    end: new Date(apt.end),
    allDay: apt.allDay,
    agent: apt.agent,
    // Add any other relevant fields from your appointment data
  }));

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: '#3174ad',
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    };
    return {
      style: style
    };
  };

  const CustomTooltip = ({ event }) => (
    <div className="bg-white border border-gray-200 p-2 rounded shadow-lg">
      <h3 className="font-bold text-lg">{event.title}</h3>
      <p><strong>Agent:</strong> {event.agent}</p>
      <p><strong>Time:</strong> {moment(event.start).format('LT')} - {moment(event.end).format('LT')}</p>
      {event.allDay && <p><em>All Day Event</em></p>}
      {/* Add any other relevant details here */}
    </div>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Upcoming Appointments">
      <div style={{ height: '500px' }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '100%' }}
          eventPropGetter={eventStyleGetter}
          tooltipAccessor={null} // Disable default tooltip
          components={{
            eventWrapper: ({ event, children }) => (
              <div title={event.title}>
                {children}
                <CustomTooltip event={event} />
              </div>
            ),
          }}
        />
      </div>
    </Modal>
  );
};

export default AppointmentCalendarModal;
