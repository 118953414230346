// src/components/Input.js
import React from 'react';

const Input = ({ icon: Icon, label, id, ...props }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2 font-lato" htmlFor={id}>
        {label}
      </label>
      <div className="relative">
        <input
          id={id}
          className="w-full p-2 pr-10 border border-gray-300 rounded font-lato"
          {...props}
        />
        {Icon && <Icon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />}
      </div>
    </div>
  );
};

export default Input;