import React from 'react';

const TabView = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div>
      <div className="flex border-b">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`py-2 px-4 ${
              activeTab === index
                ? 'border-b-2 border-black font-semibold'
                : 'text-gray-500 hover:text-black'
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="mt-4">{tabs[activeTab].content}</div>
    </div>
  );
};

export default TabView;
