import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

// QuickActionButton component
export const QuickActionButton = ({ label, onClick, to }) => {
  if (to) {
    return (
      <Link to={to}>
        <Button>{label}</Button>
      </Link>
    );
  }

  return (
    <Button onClick={onClick}>
      {label}
    </Button>
  );
};

// QuickActions component
const QuickActions = ({ actions }) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 mb-8">
      {actions.map((action, index) => (
        <QuickActionButton
          key={index}
          label={action.label}
          onClick={action.onClick}
          to={action.to}
        />
      ))}
    </div>
  );
};

export default QuickActions;