import React, { useState, useEffect, useRef } from 'react';
import PropertyFilters from '../components/PropertyFilters';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';
import { Home, DollarSign, Users, Bed, Bath, Calendar, Car, Eye, Zap, ChevronLeft, ChevronRight } from 'lucide-react';
import KPICard from '../components/KPICard';
import QuickActions from '../components/QuickActions';
import TabView from '../components/TabView';
import Modal from '../components/Modal';
import Button from '../components/Button';
import propertyData from '../data/propertyData.json';
import usersData from '../data/usersData.json';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import axios from 'axios';

// Don't forget to import the CSS for the marker cluster
import '@changey/react-leaflet-markercluster/dist/styles.min.css';

// Fix for default marker icon
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = Icon.extend({
  options: {
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  }
});
Icon.Default.mergeOptions({
  iconRetinaUrl: icon,
  iconUrl: icon,
  shadowUrl: iconShadow
});

// POI types and their corresponding icons
const poiTypes = {
  hospital: { icon: 'hospital-icon.png', color: 'red' },
  school: { icon: 'school-icon.png', color: 'green' },
  restaurant: { icon: 'restaurant-icon.png', color: 'orange' },
  park: { icon: 'park-icon.png', color: 'darkgreen' },
  // Add a default type for any unexpected POI types
  default: { icon: 'default-icon.png', color: 'gray' }
};

// Custom hook for viewport state
const useViewport = () => {
  const [viewport, setViewport] = useState(null);
  const map = useMapEvents({
    moveend: () => {
      const bounds = map.getBounds();
      setViewport({
        minLat: bounds.getSouth(),
        maxLat: bounds.getNorth(),
        minLon: bounds.getWest(),
        maxLon: bounds.getEast(),
        zoom: map.getZoom()
      });
    }
  });
  return viewport;
};

const RADIUS = 1200; // 1.2km in meters

function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371000; // Earth's radius in meters
  const φ1 = lat1 * Math.PI / 180;
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lon2 - lon1) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

const MapContent = ({ selectedProperty }) => {
  const [pois, setPois] = useState([]);
  const map = useMap();
  const poiCache = useRef(new Map());

  useEffect(() => {
    if (selectedProperty) {
      map.setView([selectedProperty.latitude, selectedProperty.longitude], 15);
      fetchPOIs(selectedProperty);
    }
  }, [selectedProperty, map]);

  const fetchPOIs = async (property) => {
    const cacheKey = `${property.latitude},${property.longitude}`;
    if (poiCache.current.has(cacheKey)) {
      setPois(poiCache.current.get(cacheKey));
      return;
    }

    const query = `
      [out:json];
      (
        node["amenity"~"hospital|school"]["name"](around:${RADIUS},${property.latitude},${property.longitude});
        way["amenity"~"hospital|school"]["name"](around:${RADIUS},${property.latitude},${property.longitude});
        relation["amenity"~"hospital|school"]["name"](around:${RADIUS},${property.latitude},${property.longitude});
        node["leisure"="park"]["name"](around:${RADIUS},${property.latitude},${property.longitude});
        way["leisure"="park"]["name"](around:${RADIUS},${property.latitude},${property.longitude});
        relation["leisure"="park"]["name"](around:${RADIUS},${property.latitude},${property.longitude});
      );
      out center;
    `;

    try {
      const response = await axios.post('https://overpass-api.de/api/interpreter', query);
      const newPois = response.data.elements
        .map(element => ({
          id: element.id,
          lat: element.lat || element.center.lat,
          lon: element.lon || element.center.lon,
          name: element.tags.name || 'Unnamed',
          type: element.tags.amenity || element.tags.leisure || 'default'
        }))
        .filter(poi => 
          haversineDistance(property.latitude, property.longitude, poi.lat, poi.lon) <= RADIUS
        );
      setPois(newPois);
      poiCache.current.set(cacheKey, newPois);
    } catch (error) {
      console.error('Error fetching POIs:', error);
    }
  };

  return (
    <>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[selectedProperty.latitude, selectedProperty.longitude]}>
        <Popup>{selectedProperty.title}</Popup>
      </Marker>
      <Circle 
        center={[selectedProperty.latitude, selectedProperty.longitude]}
        radius={RADIUS}
        pathOptions={{ fillColor: 'blue', fillOpacity: 0.1, color: 'blue', weight: 1 }}
      />
      <MarkerClusterGroup>
        {pois.map(poi => {
          const poiType = poiTypes[poi.type] || poiTypes.default;
          return (
            <Marker
              key={poi.id}
              position={[poi.lat, poi.lon]}
              icon={new Icon({
                iconUrl: poiType.icon,
                iconSize: [25, 25],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              })}
            >
              <Popup>
                <span style={{ color: poiType.color }}>{poi.name}</span>
              </Popup>
            </Marker>
          );
        })}
      </MarkerClusterGroup>
    </>
  );
};

const PropertyManagementPage = () => {
  const [isAddPropertyModalOpen, setIsAddPropertyModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [propertyFormData, setPropertyFormData] = useState({
    title: '',
    type: '',
    status: '',
    price: '',
    currency: 'MAD',
    bedrooms: '',
    bathrooms: '',
    area: '',
    areaUnit: 'sqm',
    address: '',
    city: '',
    neighborhood: '',
    description: '',
    features: [],
    yearBuilt: '',
    parkingSpaces: '',
    view: '',
    energyRating: '',
    agent: '',
    latitude: '',
    longitude: ''
  });
  const [filters, setFilters] = useState({
    priceMin: '',
    priceMax: '',
    type: '',
    bedrooms: '',
    bathrooms: '',
    status: '',
  });
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 4;

  useEffect(() => {
    // Initialize properties from propertyData
    setProperties(propertyData.properties || []);
  }, []);

  useEffect(() => {
    // Apply filters whenever filters or properties change
    const filtered = properties.filter(property => {
      return (
        (!filters.priceMin || property.price >= Number(filters.priceMin)) &&
        (!filters.priceMax || property.price <= Number(filters.priceMax)) &&
        (!filters.type || property.type === filters.type) &&
        (!filters.bedrooms || property.bedrooms >= Number(filters.bedrooms)) &&
        (!filters.bathrooms || property.bathrooms >= Number(filters.bathrooms)) &&
        (!filters.status || property.status === filters.status)
      );
    });
    setFilteredProperties(filtered);
  }, [filters, properties]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPropertyFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFeatureChange = (feature) => {
    setPropertyFormData(prev => ({
      ...prev,
      features: prev.features.includes(feature)
        ? prev.features.filter(f => f !== feature)
        : [...prev.features, feature]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement property creation logic
    console.log(propertyFormData);
    setIsAddPropertyModalOpen(false);
  };

  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, totalSteps));
  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 1));

  const quickActions = [
    { label: "Add New Property", onClick: () => setIsAddPropertyModalOpen(true) },
    { label: "Schedule Viewing", onClick: () => {/* TODO: Implement Schedule Viewing */} },
    { label: "Generate Report", onClick: () => {/* TODO: Implement Generate Report */} },
  ];

  const agents = usersData.users.filter(user => user.role === 'agent');

  const renderPropertyList = () => (
    <div className="overflow-y-auto h-[calc(100vh-200px)]">
      {filteredProperties.map(property => (
        <div 
          key={property.id} 
          className={`p-4 border-b cursor-pointer hover:bg-gray-50 transition-colors duration-150 ${selectedProperty && selectedProperty.id === property.id ? 'bg-gray-100' : ''}`}
          onClick={() => setSelectedProperty(property)}
        >
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-semibold text-lg">{property.title}</h3>
            <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusColor(property.status)}`}>
              {property.status}
            </span>
          </div>
          <p className="text-sm text-gray-600">{property.type}</p>
          <p className="text-sm font-semibold mt-1">{property.price} {property.currency}</p>
        </div>
      ))}
    </div>
  );

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'sold':
        return 'bg-blue-100 text-blue-800';
      case 'rented':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const renderMap = () => {
    if (!selectedProperty) return null;

    const position = [selectedProperty.latitude, selectedProperty.longitude];

    return (
      <MapContainer center={position} zoom={15} style={{ height: '600px', width: '100%' }}>
        <MapContent selectedProperty={selectedProperty} />
      </MapContainer>
    );
  };

  const renderPropertyDetails = () => {
    if (!selectedProperty) return <p className="text-center text-gray-500 mt-8">Select a property to view details</p>;

    const detailItems = [
      { icon: <Bed size={20} />, label: 'Bedrooms', value: selectedProperty.bedrooms },
      { icon: <Bath size={20} />, label: 'Bathrooms', value: selectedProperty.bathrooms },
      { icon: <Calendar size={20} />, label: 'Year Built', value: selectedProperty.yearBuilt },
      { icon: <Car size={20} />, label: 'Parking Spaces', value: selectedProperty.parkingSpaces },
      { icon: <Eye size={20} />, label: 'View', value: selectedProperty.view },
      { icon: <Zap size={20} />, label: 'Energy Rating', value: selectedProperty.energyRating },
    ];

    return (
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">{selectedProperty.title}</h2>
        
        <p className="text-gray-700 mb-6">{selectedProperty.description}</p>
        
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <p className="text-sm text-gray-600">Type</p>
            <p className="font-semibold">{selectedProperty.type}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Status</p>
            <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusColor(selectedProperty.status)}`}>
              {selectedProperty.status}
            </span>
          </div>
          <div>
            <p className="text-sm text-gray-600">Price</p>
            <p className="font-semibold">{selectedProperty.price} {selectedProperty.currency}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Area</p>
            <p className="font-semibold">{selectedProperty.area} {selectedProperty.areaUnit}</p>
          </div>
        </div>
        
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2 text-gray-800">Location</h3>
          <p>{selectedProperty.address}</p>
          <p>{selectedProperty.neighborhood}, {selectedProperty.city}</p>
        </div>
        
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2 text-gray-800">Details</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {detailItems.map((item, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="text-gray-500">{item.icon}</div>
                <div>
                  <p className="text-sm text-gray-600">{item.label}</p>
                  <p className="font-semibold">{item.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2 text-gray-800">Features</h3>
          <div className="flex flex-wrap gap-2">
            {selectedProperty.features.map((feature, index) => (
              <span key={index} className="bg-gray-100 text-gray-800 text-sm font-medium px-2.5 py-0.5 rounded">
                {feature}
              </span>
            ))}
          </div>
        </div>
        
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2 text-gray-800">Assigned Agent</h3>
          <p>Agent ID: {selectedProperty.agent}</p>
          {/* You can add more agent details here if available */}
        </div>
        
        <div>
          <h3 className="text-lg font-semibold mb-2 text-gray-800">Location</h3>
          {renderMap()}
        </div>
      </div>
    );
  };

  const renderTransactions = () => {
    if (!selectedProperty) return <p>Select a property to view transactions</p>;

    return (
      <div>
        <h3 className="text-xl font-semibold mb-4">Transactions for {selectedProperty.title}</h3>
        {/* TODO: Implement transactions list for the selected property */}
        <p>Transaction history will be displayed here.</p>
      </div>
    );
  };

  const renderAnalytics = () => {
    if (!selectedProperty) return <p className="text-center text-gray-500 mt-8">Select a property to view analytics</p>;

    const { analytics } = selectedProperty;

    return (
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Property Analytics</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Viewing Statistics</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={analytics.viewingStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="views" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Inquiry Analysis</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={analytics.inquiryAnalysis}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="type" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="bg-gray-100 p-4 rounded-lg">
            <h4 className="text-lg font-semibold mb-2 text-gray-800">Total Views</h4>
            <p className="text-3xl font-bold text-indigo-600">{analytics.totalViews}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h4 className="text-lg font-semibold mb-2 text-gray-800">Total Inquiries</h4>
            <p className="text-3xl font-bold text-indigo-600">{analytics.totalInquiries}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h4 className="text-lg font-semibold mb-2 text-gray-800">Conversion Rate</h4>
            <p className="text-3xl font-bold text-indigo-600">{analytics.conversionRate}%</p>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4 text-gray-800">Price History</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={analytics.priceHistory}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="price" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Time on Market</h3>
            <p className="text-3xl font-bold text-indigo-600">{analytics.timeOnMarket} days</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Comparable Properties</h3>
            <p className="text-lg">This property is priced {analytics.priceComparison}% {analytics.priceComparison > 0 ? 'above' : 'below'} similar properties in the area.</p>
          </div>
        </div>
      </div>
    );
  };

  const renderMaintenance = () => {
    if (!selectedProperty) return <p>Select a property to view maintenance requests</p>;

    return (
      <div>
        <h3 className="text-xl font-semibold mb-4">Maintenance Requests for {selectedProperty.title}</h3>
        {/* TODO: Implement maintenance requests list for the selected property */}
        <p>Maintenance requests and schedules will be displayed here.</p>
      </div>
    );
  };

  const renderDocuments = () => {
    if (!selectedProperty) return <p>Select a property to view documents</p>;

    return (
      <div>
        <h3 className="text-xl font-semibold mb-4">Documents for {selectedProperty.title}</h3>
        {/* TODO: Implement document management system for the selected property */}
        <p>Property-related documents and contracts will be managed here.</p>
      </div>
    );
  };

  const renderStepContent = () => {
    switch(currentStep) {
      case 1:
        return (
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Basic Information</h3>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Title</label>
                <input type="text" name="title" value={propertyFormData.title} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Type</label>
                <select name="type" value={propertyFormData.type} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
                  <option value="">Select Type</option>
                  {propertyData.propertyTypes.map(type => (
                    <option key={type.value} value={type.value}>{type.label}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Status</label>
                <select name="status" value={propertyFormData.status} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
                  <option value="">Select Status</option>
                  {propertyData.propertyStatuses.map(status => (
                    <option key={status.value} value={status.value}>{status.label}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Price</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input type="number" name="price" value={propertyFormData.price} onChange={handleChange} className="flex-1 min-w-0 block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                  <select name="currency" value={propertyFormData.currency} onChange={handleChange} className="flex-shrink-0 inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-100 rounded-r-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {propertyData.currencies.map(currency => (
                      <option key={currency.value} value={currency.value}>{currency.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Property Details</h3>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Bedrooms</label>
                <input type="number" name="bedrooms" value={propertyFormData.bedrooms} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Bathrooms</label>
                <input type="number" name="bathrooms" value={propertyFormData.bathrooms} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Area</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input type="number" name="area" value={propertyFormData.area} onChange={handleChange} className="flex-1 min-w-0 block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                  <select name="areaUnit" value={propertyFormData.areaUnit} onChange={handleChange} className="flex-shrink-0 inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-100 rounded-r-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {propertyData.areaUnits.map(unit => (
                      <option key={unit.value} value={unit.value}>{unit.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Year Built</label>
                <input type="number" name="yearBuilt" value={propertyFormData.yearBuilt} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Location</h3>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <input type="text" name="address" value={propertyFormData.address} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">City</label>
                <input type="text" name="city" value={propertyFormData.city} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Neighborhood</label>
                <input type="text" name="neighborhood" value={propertyFormData.neighborhood} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Additional Information</h3>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea name="description" value={propertyFormData.description} onChange={handleChange} rows="3" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Features</label>
                <div className="mt-2 space-y-2">
                  {propertyData.propertyFeatures.map(feature => (
                    <div key={feature} className="flex items-center">
                      <input
                        type="checkbox"
                        id={feature}
                        name="features"
                        value={feature}
                        checked={propertyFormData.features.includes(feature)}
                        onChange={() => handleFeatureChange(feature)}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor={feature} className="ml-2 block text-sm text-gray-900">
                        {feature}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Assigned Agent</label>
                <select name="agent" value={propertyFormData.agent} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                  <option value="">Select Agent</option>
                  {agents.map(agent => (
                    <option key={agent.id} value={agent.id}>{agent.firstName} {agent.lastName}</option>
                  ))}
                </select>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-screen-2xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800 font-playfair text-center">Property Management</h1>
      
      <QuickActions actions={quickActions} />
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <KPICard 
          title="Total Properties" 
          value={properties.length.toString()}
          icon={Home}
          color="border-l-4 border-blue-500"
        />
        <KPICard 
          title="Total Agents" 
          value={agents.length.toString()}
          icon={Users}
          color="border-l-4 border-green-500"
        />
        <KPICard 
          title="Total Revenue" 
          value={`${agents.reduce((sum, agent) => sum + agent.totalSales, 0)} MAD`}
          icon={DollarSign}
          color="border-l-4 border-yellow-500"
        />
      </div>

      <div className="flex">
        {/* Filters Sidebar */}
        <div className="w-1/6 pr-4">
          <h2 className="text-xl font-semibold mb-4">Filters</h2>
          <PropertyFilters 
            filters={filters} 
            setFilters={setFilters}
            propertyTypes={propertyData.propertyTypes}
            propertyStatuses={propertyData.propertyStatuses}
          />
        </div>

        {/* Property List */}
        <div className="w-1/4 px-4 border-l border-r">
          <h2 className="text-xl font-semibold mb-4">Properties</h2>
          {renderPropertyList()}
        </div>

        {/* Property Details */}
        <div className="w-7/12 pl-4">
          <TabView
            tabs={[
              { label: "Details", content: renderPropertyDetails() },
              { label: "Transactions", content: renderTransactions() },
              { label: "Analytics", content: renderAnalytics() },
              { label: "Maintenance", content: renderMaintenance() },
              { label: "Documents", content: renderDocuments() },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>

      <Modal 
        isOpen={isAddPropertyModalOpen} 
        onClose={() => setIsAddPropertyModalOpen(false)}
        title="Add New Property"
        size="lg"
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          {renderStepContent()}
          <div className="flex justify-between mt-8">
            {currentStep > 1 && (
              <Button onClick={prevStep} variant="secondary" className="flex items-center">
                <ChevronLeft className="w-4 h-4 mr-2" /> Previous
              </Button>
            )}
            {currentStep < totalSteps ? (
              <Button onClick={nextStep} className="ml-auto flex items-center">
                Next <ChevronRight className="w-4 h-4 ml-2" />
              </Button>
            ) : (
              <Button type="submit" className="ml-auto">
                Add Property
              </Button>
            )}
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PropertyManagementPage;