import React, { useState, useEffect } from 'react';
import KPICard from '../components/KPICard';
import PlaceholderSection from '../components/PlaceholderSection';
import AddPropertyModal from '../components/modals/AddPropertyModal';
import { Home, Percent, DollarSign, Users, TrendingUp, Calendar } from 'lucide-react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import realEstateData from '../data/realEstateData.json';
import AppointmentCalendarModal from '../components/AppointmentCalendarModal';

const DashboardPage = () => {
  const [isAddPropertyModalOpen, setIsAddPropertyModalOpen] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    totalProperties: 0,
    occupancyRate: 0,
    totalRevenue: 0,
    totalClients: 0,
    clientGrowthRate: 0,
    upcomingAppointments: 0,
  });

  useEffect(() => {
    // Simulating API call to fetch dashboard data
    const fetchDashboardData = async () => {
      // In a real application, this would be an API call
      const data = {
        totalProperties: 50,
        occupancyRate: 85,
        totalRevenue: 500000,
        totalClients: 120,
        clientGrowthRate: 5.2,
        upcomingAppointments: 8,
      };
      setDashboardData(data);
    };

    fetchDashboardData();
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentValue = (percent * 100).toFixed(0);

    if (percentValue < 5) return null; // Don't show label for small slices

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize="12">
        {`${payload.type} ${percentValue}%`}
      </text>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800 font-playfair text-center">Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <KPICard 
          title="Total Properties" 
          value={dashboardData.totalProperties.toString()}
          icon={Home}
          color="border-l-4 border-blue-500"
        />
        <KPICard 
          title="Occupancy Rate" 
          value={`${dashboardData.occupancyRate}%`}
          icon={Percent}
          color="border-l-4 border-green-500"
        />
        <KPICard 
          title="Total Revenue" 
          value={`$${dashboardData.totalRevenue.toLocaleString()}`}
          icon={DollarSign}
          color="border-l-4 border-yellow-500"
        />
        <KPICard 
          title="Total Clients" 
          value={dashboardData.totalClients.toString()}
          icon={Users}
          color="border-l-4 border-purple-500"
        />
        <KPICard 
          title="Client Growth Rate" 
          value={`${dashboardData.clientGrowthRate}%`}
          icon={TrendingUp}
          color="border-l-4 border-pink-500"
        />
        <KPICard 
          title="Upcoming Appointments" 
          value={dashboardData.upcomingAppointments.toString()}
          icon={Calendar}
          color="border-l-4 border-indigo-500"
          onClick={() => setIsCalendarModalOpen(true)}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Monthly Revenue</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={realEstateData.monthlyRevenue}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Properties by Type</h2>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={realEstateData.propertiesByType}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="count"
                nameKey="type"
              >
                {realEstateData.propertiesByType.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={(value, name, props) => [`${value} (${((value / realEstateData.propertiesByType.reduce((sum, { count }) => sum + count, 0)) * 100).toFixed(1)}%)`, props.payload.type]} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">New Client Acquisition</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={realEstateData.clientAcquisition}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="newClients" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Occupancy Rate History</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={realEstateData.occupancyRateHistory}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="rate" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        {/* Top Performing Agents */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Top Performing Agents</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Name</th>
                  <th className="px-4 py-2 text-left">Sales</th>
                  <th className="px-4 py-2 text-left">Transactions</th>
                </tr>
              </thead>
              <tbody>
                {realEstateData.topPerformingAgents.map((agent, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                    <td className="px-4 py-2">{agent.name}</td>
                    <td className="px-4 py-2">${agent.sales.toLocaleString()}</td>
                    <td className="px-4 py-2">{agent.transactions}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Property Pipeline */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Property Pipeline</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={realEstateData.propertyPipeline}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="stage" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Market Trends */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Market Trends</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={realEstateData.marketTrends}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="averagePrice" stroke="#8884d8" name="Avg. Price" />
              <Line yAxisId="right" type="monotone" dataKey="rentRate" stroke="#82ca9d" name="Rent Rate" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        {/* Top Performing Agents */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Top Performing Agents</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Name</th>
                  <th className="px-4 py-2 text-left">Sales</th>
                  <th className="px-4 py-2 text-left">Transactions</th>
                </tr>
              </thead>
              <tbody>
                {realEstateData.topPerformingAgents.map((agent, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                    <td className="px-4 py-2">{agent.name}</td>
                    <td className="px-4 py-2">${agent.sales.toLocaleString()}</td>
                    <td className="px-4 py-2">{agent.transactions}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Property Pipeline */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Property Pipeline</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={realEstateData.propertyPipeline}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="stage" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Market Trends */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Market Trends</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={realEstateData.marketTrends}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="averagePrice" stroke="#8884d8" name="Avg. Price" />
              <Line yAxisId="right" type="monotone" dataKey="rentRate" stroke="#82ca9d" name="Rent Rate" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <PlaceholderSection title="Recent Activities" />
      <PlaceholderSection title="Upcoming Tasks" />

      <AddPropertyModal 
        isOpen={isAddPropertyModalOpen} 
        onClose={() => setIsAddPropertyModalOpen(false)}
      />

      <AppointmentCalendarModal
        isOpen={isCalendarModalOpen}
        onClose={() => setIsCalendarModalOpen(false)}
        appointments={realEstateData.appointments}
      />
    </div>
  );
};

export default DashboardPage;