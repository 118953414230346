import React from 'react';

const Button = ({ children, onClick, className = '', variant = 'outline', ...props }) => {
  const baseClasses = 'px-6 py-2 rounded-md transition-all duration-300 font-lato';
  const variantClasses = {
    primary: 'bg-black text-white hover:bg-gray-800',
    secondary: 'bg-white text-black border border-black hover:bg-gray-100',
    outline: 'bg-transparent text-black border border-black hover:bg-black hover:text-white'
  };

  return (
    <button
      onClick={onClick}
      className={`${baseClasses} ${variantClasses[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;