import React from 'react';
import { X } from 'lucide-react';

const PropertyFilters = ({ filters, setFilters, propertyTypes, propertyStatuses }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const clearFilters = () => {
    setFilters({
      priceMin: '',
      priceMax: '',
      type: '',
      bedrooms: '',
      bathrooms: '',
      status: '',
    });
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Filters</h3>
        <button onClick={clearFilters} className="text-sm text-gray-500 flex items-center">
          Clear All <X size={16} className="ml-1" />
        </button>
      </div>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Price Range</label>
          <input
            type="number"
            name="priceMin"
            value={filters.priceMin}
            onChange={handleChange}
            placeholder="Min Price"
            className="block w-full px-3 py-2 rounded-md text-sm border-gray-300 mb-2"
          />
          <input
            type="number"
            name="priceMax"
            value={filters.priceMax}
            onChange={handleChange}
            placeholder="Max Price"
            className="block w-full px-3 py-2 rounded-md text-sm border-gray-300"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Property Type</label>
          <select
            name="type"
            value={filters.type}
            onChange={handleChange}
            className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm text-sm"
          >
            <option value="">All Types</option>
            {propertyTypes.map(type => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
          <select
            name="status"
            value={filters.status}
            onChange={handleChange}
            className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm text-sm"
          >
            <option value="">All Statuses</option>
            {propertyStatuses.map(status => (
              <option key={status.value} value={status.value}>{status.label}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Bedrooms</label>
          <input
            type="number"
            name="bedrooms"
            value={filters.bedrooms}
            onChange={handleChange}
            placeholder="Min Bedrooms"
            className="block w-full px-3 py-2 rounded-md text-sm border-gray-300"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Bathrooms</label>
          <input
            type="number"
            name="bathrooms"
            value={filters.bathrooms}
            onChange={handleChange}
            placeholder="Min Bathrooms"
            className="block w-full px-3 py-2 rounded-md text-sm border-gray-300"
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyFilters;