import React from 'react';

const KPICard = ({ title, value, icon: Icon, color, onClick }) => {
  return (
    <div className={`bg-white rounded-lg shadow p-6 ${color} cursor-pointer`} onClick={onClick}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon className="h-8 w-8 text-gray-400" />
        </div>
        <div className="ml-5 w-0 flex-1">
          <dl>
            <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
            <dd className="text-3xl font-semibold text-gray-900">{value}</dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default KPICard;
