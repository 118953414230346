import React from 'react';

const PlaceholderSection = ({ title }) => (
  <div className="bg-white shadow-md rounded-lg p-6">
    <h2 className="text-xl font-semibold mb-4 font-playfair">{title}</h2>
    <p className="font-lato">This section is under development.</p>
  </div>
);

export default PlaceholderSection;
