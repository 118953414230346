import React, { useState, useEffect } from 'react';
import { Users, DollarSign, Home, Phone, Mail, Calendar, FileText, PlusCircle, Upload, Download, Trash2, Edit3, MessageCircle, Eye, TrendingUp } from 'lucide-react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import KPICard from '../components/KPICard';
import QuickActions from '../components/QuickActions';
import TabView from '../components/TabView';
import Button from '../components/Button';
import Modal from '../components/Modal';
import usersData from '../data/usersData.json';

const ClientManagementPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isScheduleMeetingModalOpen, setIsScheduleMeetingModalOpen] = useState(false);
  const [meetingData, setMeetingData] = useState({
    clientId: '',
    type: '',
    date: '',
    time: '',
    duration: '30', // Default to 30 minutes
    location: '',
    description: '',
    sendReminder: true,
  });
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [emailData, setEmailData] = useState({ subject: '', message: '' });

  // Get today's date in YYYY-MM-DD format for the min attribute of the date input
  const today = new Date().toISOString().split('T')[0];

  const durationOptions = [
    { value: '15', label: '15 minutes' },
    { value: '30', label: '30 minutes' },
    { value: '60', label: '1 hour' },
    { value: '90', label: '1 hour 30 minutes' },
    { value: '120', label: '2 hours' },
    { value: '150', label: '2 hours 30 minutes' },
    { value: '180', label: '3 hours' },
  ];

  useEffect(() => {
    // Filter clients from usersData
    const clientData = usersData.users.filter(user => user.role === 'client');
    setClients(clientData);
  }, []);

  const quickActions = [
    { label: "Add New Client", onClick: () => setIsModalOpen(true), icon: PlusCircle },
    { label: "Generate Report", onClick: () => {/* TODO: Implement Generate Report */}, icon: FileText },
  ];

  const handleScheduleMeeting = (clientId) => {
    setMeetingData(prev => ({ ...prev, clientId }));
    setIsScheduleMeetingModalOpen(true);
  };

  const handleMeetingInputChange = (e) => {
    const { name, value } = e.target;
    setMeetingData(prev => ({ ...prev, [name]: value }));
  };

  const handleMeetingSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement meeting scheduling logic
    console.log(meetingData);
    setIsScheduleMeetingModalOpen(false);
  };

  const handleSendEmail = () => {
    setIsEmailModalOpen(true);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement email sending logic
    console.log('Sending email:', emailData);
    setIsEmailModalOpen(false);
    setEmailData({ subject: '', message: '' });
  };

  const handleUpdateStatus = () => {
    setIsStatusModalOpen(true);
  };

  const handleStatusUpdate = (newStatus) => {
    // TODO: Implement status update logic
    console.log('Updating status to:', newStatus);
    setIsStatusModalOpen(false);
    // Update the client's status in your state/database
  };

  const renderClientsList = () => (
    <div className="overflow-y-auto h-[calc(100vh-200px)]">
      {clients.map(client => (
        <div 
          key={client.id} 
          className={`p-4 border-b cursor-pointer hover:bg-gray-50 transition-colors duration-150 ${selectedClient && selectedClient.id === client.id ? 'bg-gray-100' : ''}`}
          onClick={() => setSelectedClient(client)}
        >
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-semibold text-lg">{`${client.firstName} ${client.lastName}`}</h3>
            <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusColor(client.status)}`}>
              {client.status || 'N/A'}
            </span>
          </div>
          <p className="text-sm text-gray-600">{client.email}</p>
          <p className="text-sm font-semibold mt-1">{client.phone}</p>
        </div>
      ))}
    </div>
  );

  const renderClientDetails = () => {
    if (!selectedClient) return <p className="text-center text-gray-500 mt-8">Select a client to view details</p>;

    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl leading-6 font-semibold text-gray-900">
              {`${selectedClient.firstName} ${selectedClient.lastName}`}
            </h3>
            <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusColor(selectedClient.status)}`}>
              {selectedClient.status || 'N/A'}
            </span>
          </div>
          <div className="flex flex-wrap gap-2">
            <Button 
              onClick={() => handleScheduleMeeting(selectedClient.id)} 
              variant="primary" 
              className="flex items-center"
            >
              <Calendar className="w-4 h-4 mr-2" /> Schedule Meeting
            </Button>
            <Button 
              onClick={handleSendEmail} 
              variant="secondary" 
              className="flex items-center"
            >
              <MessageCircle className="w-4 h-4 mr-2" /> Send Email/Message
            </Button>
            <Button 
              onClick={handleUpdateStatus} 
              variant="secondary" 
              className="flex items-center"
            >
              <Edit3 className="w-4 h-4 mr-2" /> Update Status
            </Button>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedClient.email}</dd>
            </div>
            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedClient.phone}</dd>
            </div>
            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Budget</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedClient.budget} MAD</dd>
            </div>
            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Preferred Locations</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedClient.preferredLocations?.join(', ') || 'N/A'}
              </dd>
            </div>
            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Property Types</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedClient.propertyTypes?.join(', ') || 'N/A'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  const renderClientInteractions = () => {
    if (!selectedClient) return <p className="text-center text-gray-500 mt-8">Select a client to view interactions</p>;

    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Client Interactions
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Last interaction</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedClient.lastInteraction || 'N/A'}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Next follow-up</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedClient.nextFollowUp || 'Not scheduled'}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Notes</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedClient.notes || 'No notes available'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  const renderDocuments = () => {
    if (!selectedClient) return <p className="text-center text-gray-500 mt-8">Select a client to view documents</p>;

    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Client Documents
          </h3>
          <Button onClick={() => {/* TODO: Implement document upload */}} className="flex items-center">
            <Upload className="w-4 h-4 mr-2" /> Upload Document
          </Button>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {documents.map((doc, index) => (
              <li key={index} className="px-4 py-4 sm:px-6 flex items-center justify-between">
                <div className="flex items-center">
                  <FileText className="w-5 h-5 mr-3 text-gray-400" />
                  <span className="text-sm font-medium text-gray-900">{doc.name}</span>
                </div>
                <div className="flex space-x-2">
                  <Button onClick={() => {/* TODO: Implement download */}} variant="secondary" size="sm">
                    <Download className="w-4 h-4" />
                  </Button>
                  <Button onClick={() => {/* TODO: Implement delete */}} variant="danger" size="sm">
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const renderAnalytics = () => {
    if (!selectedClient || !selectedClient.analytics) {
      return <p className="text-center text-gray-500 mt-8">No analytics data available for this client</p>;
    }

    const { analytics } = selectedClient;

    return (
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Client Analytics</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Property Viewings</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={analytics.propertyViewings}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Inquiries by Type</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={analytics.inquiries}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="type" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="bg-gray-100 p-4 rounded-lg">
            <h4 className="text-lg font-semibold mb-2 text-gray-800">Total Interactions</h4>
            <p className="text-3xl font-bold text-indigo-600">{analytics.totalInteractions}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h4 className="text-lg font-semibold mb-2 text-gray-800">Last Interaction</h4>
            <p className="text-3xl font-bold text-indigo-600">{analytics.lastInteraction}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h4 className="text-lg font-semibold mb-2 text-gray-800">Conversion Likelihood</h4>
            <p className="text-3xl font-bold text-indigo-600">{(analytics.conversionLikelihood * 100).toFixed(1)}%</p>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4 text-gray-800">Budget History</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={analytics.budgetHistory}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="amount" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4 text-gray-800">Interaction History</h3>
          <ul className="divide-y divide-gray-200">
            {analytics.interactionHistory.map((interaction, index) => (
              <li key={index} className="py-4">
                <p className="text-sm font-medium text-gray-900">{interaction.date}</p>
                <p className="text-sm text-gray-500">{interaction.type}: {interaction.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const tabs = [
    { label: "Details", content: renderClientDetails() },
    { label: "Interactions", content: renderClientInteractions() },
    { label: "Documents", content: renderDocuments() },
    { label: "Analytics", content: renderAnalytics() },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800 font-playfair text-center">Client Management</h1>
      
      <QuickActions actions={quickActions} />
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <KPICard 
          title="Total Clients" 
          value={clients.length.toString()}
          icon={Users}
          color="border-l-4 border-blue-500"
        />
        <KPICard 
          title="Average Budget" 
          value={`${Math.round(clients.reduce((sum, client) => sum + (client.budget || 0), 0) / clients.length)} MAD`}
          icon={DollarSign}
          color="border-l-4 border-green-500"
        />
        <KPICard 
          title="Most Popular Property Type" 
          value={getMostPopularPropertyType(clients)}
          icon={Home}
          color="border-l-4 border-yellow-500"
        />
      </div>

      <div className="flex">
        <div className="w-1/3 pr-4">
          <h2 className="text-xl font-semibold mb-4">Clients</h2>
          {renderClientsList()}
        </div>
        <div className="w-2/3">
          <TabView
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add New Client"
      >
        {/* Add New Client Form */}
        <p>Form to add a new client goes here.</p>
      </Modal>

      <Modal
        isOpen={isScheduleMeetingModalOpen}
        onClose={() => setIsScheduleMeetingModalOpen(false)}
        title="Schedule Meeting"
      >
        <form onSubmit={handleMeetingSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Meeting Type</label>
            <select
              name="type"
              value={meetingData.type}
              onChange={handleMeetingInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            >
              <option value="">Select Type</option>
              <option value="Property Viewing">Property Viewing</option>
              <option value="Consultation">Consultation</option>
              <option value="Contract Signing">Contract Signing</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                name="date"
                value={meetingData.date}
                onChange={handleMeetingInputChange}
                min={today} // Set minimum date to today
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Time</label>
              <input
                type="time"
                name="time"
                value={meetingData.time}
                onChange={handleMeetingInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Duration</label>
            <select
              name="duration"
              value={meetingData.duration}
              onChange={handleMeetingInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            >
              {durationOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Location</label>
            <input
              type="text"
              name="location"
              value={meetingData.location}
              onChange={handleMeetingInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              name="description"
              value={meetingData.description}
              onChange={handleMeetingInputChange}
              rows="3"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            ></textarea>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="sendReminder"
              name="sendReminder"
              checked={meetingData.sendReminder}
              onChange={(e) => setMeetingData(prev => ({ ...prev, sendReminder: e.target.checked }))}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="sendReminder" className="ml-2 block text-sm text-gray-900">
              Send reminder to client
            </label>
          </div>
          <div className="flex justify-end space-x-2">
            <Button onClick={() => setIsScheduleMeetingModalOpen(false)} variant="secondary">Cancel</Button>
            <Button type="submit">Schedule Meeting</Button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        title="Send Email/Message"
      >
        <form onSubmit={handleEmailSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Subject</label>
            <input
              type="text"
              value={emailData.subject}
              onChange={(e) => setEmailData(prev => ({ ...prev, subject: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Message</label>
            <textarea
              value={emailData.message}
              onChange={(e) => setEmailData(prev => ({ ...prev, message: e.target.value }))}
              rows="4"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            ></textarea>
          </div>
          <div className="flex justify-end space-x-2">
            <Button onClick={() => setIsEmailModalOpen(false)} variant="secondary">Cancel</Button>
            <Button type="submit">Send</Button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        title="Update Client Status"
      >
        <div className="space-y-4">
          <Button onClick={() => handleStatusUpdate('Active')} variant="secondary" className="w-full">Active</Button>
          <Button onClick={() => handleStatusUpdate('Inactive')} variant="secondary" className="w-full">Inactive</Button>
          <Button onClick={() => handleStatusUpdate('Lead')} variant="secondary" className="w-full">Lead</Button>
          <Button onClick={() => handleStatusUpdate('Closed')} variant="secondary" className="w-full">Closed</Button>
        </div>
      </Modal>
    </div>
  );
};

function getMostPopularPropertyType(clients) {
  const typeCounts = clients.flatMap(client => client.propertyTypes || [])
    .reduce((acc, type) => {
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});
  const sortedTypes = Object.entries(typeCounts).sort((a, b) => b[1] - a[1]);
  return sortedTypes.length > 0 ? sortedTypes[0][0] : 'N/A';
}

function getStatusColor(status) {
  switch (status) {
    case 'Active':
      return 'bg-green-100 text-green-800';
    case 'Inactive':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

export default ClientManagementPage;