// src/pages/ContactPage.js
import React, { useState } from 'react';
import { Mail, Phone, ChevronDown, ChevronUp } from 'lucide-react';
import Button from '../components/Button';
import Input from '../components/Input';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold font-playfair">{question}</h3>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && (
        <p className="mt-2 text-gray-600 font-lato">{answer}</p>
      )}
    </div>
  );
};

const ContactPage = () => {
  const faqs = [
    { question: "Is there a free trial available?", answer: "Yes, we offer a 14-day free trial for all our plans." },
    { question: "Can I upgrade or downgrade my plan?", answer: "Absolutely! You can change your plan at any time." },
    { question: "Is my data secure?", answer: "We use industry-standard encryption and security measures to protect your data." },
    { question: "Do you offer training?", answer: "Yes, we provide free onboarding and training sessions for all new clients." }
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl md:text-4xl font-semibold mb-12 text-center font-playfair">Contact Us</h1>
      
      {/* Contact Form */}
      <div className="max-w-xl mx-auto mb-20">
        <div className="flex justify-center space-x-8 mb-8">
          <a href="mailto:contact@Kasaba.ma" className="flex items-center text-black hover:text-gray-600">
            <Mail className="mr-2" />
            <span className="font-lato">contact@Kasaba.ma</span>
          </a>
          <a href="tel:+212500000000" className="flex items-center text-black hover:text-gray-600">
            <Phone className="mr-2" />
            <span className="font-lato">+212 5 00 00 00 00</span>
          </a>
        </div>
        <form className="space-y-4">
          <Input label="Your Name" id="contact-name" type="text" placeholder="Your name" />
          <Input label="Your Email" id="contact-email" type="email" placeholder="Your email" />
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2 font-lato" htmlFor="contact-message">
              Your Message
            </label>
            <textarea
              id="contact-message"
              rows="4"
              className="w-full p-2 border border-gray-300 rounded font-lato"
              placeholder="Your message"
            ></textarea>
          </div>
          <Button type="submit">Send Message</Button>
        </form>
      </div>

      {/* FAQ Section */}
      <section className="max-w-2xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-semibold mb-8 text-center font-playfair">Frequently Asked Questions</h2>
        <div className="space-y-2">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default ContactPage;