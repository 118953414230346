// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, Phone } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="border-t border-gray-200 py-8 text-sm text-gray-600 font-lato">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          <div>
            <h3 className="font-bold mb-2">Kasaba</h3>
            <p>&copy; 2024 Kasaba. All rights reserved.</p>
          </div>
          <div>
            <h3 className="font-bold mb-2">Quick Links</h3>
            <ul>
              <li><Link to="/privacy" className="hover:text-black">Privacy Policy</Link></li>
              <li><Link to="/terms" className="hover:text-black">Terms of Service</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-2">Contact Us</h3>
            <ul>
              <li>
                <a href="mailto:contact@Kasaba.ma" className="flex items-center justify-center md:justify-start hover:text-black">
                  <Mail className="mr-1" size={16} />
                  contact@Kasaba.ma
                </a>
              </li>
              <li>
                <a href="tel:+212500000000" className="flex items-center justify-center md:justify-start hover:text-black">
                  <Phone className="mr-1" size={16} />
                  +212 5 00 00 00 00
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;