import React from 'react';

const Modal = ({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  size = 'md', 
  showCloseButton = true,
  closeOnOutsideClick = true
}) => {
  if (!isOpen) return null;

  const handleOutsideClick = (e) => {
    if (closeOnOutsideClick && e.target.id === 'modal-overlay') {
      onClose();
    }
  };

  const sizeClasses = {
    sm: 'max-w-md',
    md: 'max-w-2xl',
    lg: 'max-w-4xl',
    xl: 'max-w-6xl',
    full: 'max-w-full'
  };

  return (
    <div 
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center" 
      id="modal-overlay"
      onClick={handleOutsideClick}
    >
      <div className={`relative mx-auto p-5 border w-full ${sizeClasses[size]} shadow-lg rounded-md bg-white`}>
        <div className="mt-3">
          <div className="flex justify-between items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900 font-playfair">{title}</h3>
            {showCloseButton && (
              <button 
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <span className="sr-only">Close</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            )}
          </div>
          <div className="mt-2 px-7 py-3">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;